/* .pswp {
  --pswp-bg: #fff;
} */
html {
  scroll-behavior: smooth;
  scroll-padding-top: 100px;
}

.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

 /* handle active menu item */
.mobile-nav-group:has(.menu-open-true) > li > *:not(.menu-open-true) {
  opacity: 50%;
}

.location-title :is(h1, h2, h3, h4, h5, h6, strong) {
  @apply text-2xl font-light;
}

/* body:has(.header-dark) header{
  background-color: #000 !important;
}

body:has(.header-light) header {
  background-color: #fff !important;
} */